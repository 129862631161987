<template>
  <v-row>
    <v-col cols="12">
      <stats-page-header />
    </v-col>

    <v-col cols="12" class="mb-0 py-0">
      <v-row class="mt-0 pt-0">
        <v-col class="text-right mt-0 mb-0 pt-0 pb-2">
          <v-chip color="white" class="mr-2" @click="refreshData"
            >{{ t("refresh") }}
            <v-icon right color="blue light" :size="16">
              mdi-refresh
            </v-icon></v-chip
          >

          <v-chip outlined class="blue" style="background: white !important;" @click="filtersVisible = !filtersVisible"
            >{{ t("filters") }}
            <v-icon right color="blue light" :size="16">
              mdi-filter-outline
            </v-icon></v-chip
          >
        </v-col>
      </v-row>

      <v-card v-if="filtersVisible" class="p-0 mb-0">
        <v-card-text class="py-0">
          <div class="d-flex align-start">
            <div class="align-self-start">
              <ca-date-picker
                v-model="filters.dateFrom"
                :placeholder="t('date_from_placeholder')"
                :max-date="filters.dateTo ? filters.dateTo : datePickerMaxDate"
                @change="loadData"
              />
            </div>

            <div class="align-self-start">
              <ca-date-picker
                v-model="filters.dateTo"
                :placeholder="t('date_to_placeholder')"
                :min-date="filters.dateFrom"
                :max-date="datePickerMaxDate"
                @change="loadData"
              />
            </div>

            <v-select
              v-model="filters.paymentSystem"
              :items="paymentSystems"
              :label="t('payment_system')"
              dense
              class="ml-5 align-self-end"
              @change="loadData"
            ></v-select>

            <v-select
              v-model="filters.status"
              :items="statuses"
              :label="t('status')"
              dense
              class="ml-5 align-self-end"
              return-object
              item-text="text"
              item-value="value"
              @change="loadData"
            ></v-select>

            <v-switch
              v-model="filters.filesMoney"
              :label="t('files')"
              class="ml-5 align-self-end"
              @change="loadData"
            ></v-switch>
            <v-switch v-model="filters.filesCopiesMoney" class="ml-5 align-self-end" @change="loadData">
              <template #label>
                <span class="text-no-wrap">{{ t("files_copies") }}</span>
              </template>
            </v-switch>
            <v-switch
              v-model="filters.sitesMoney"
              :label="t('sites')"
              class="ml-5 align-self-end"
              @change="loadData"
            ></v-switch>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-data-table
          :loading="loadingData"
          :headers="dataTable.headers"
          :items="items"
          :server-items-length="totalItems"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          :items-per-page="itemsPerPage"
          :footer-props="dataTable.footerProps"
          @update:sort-desc="onUpdateSortDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:page="onPageUpdate"
        >
          <template #item.file_name="{item}">
            <a :href="item.file_url" target="_blank">
              <ca-text-truncator :max-width="18" :text="item.file_name" />
            </a>
          </template>

          <template #item.country_code="{item}">
            <flag :iso="item.country_code" />
          </template>

          <template #item.method="{item}">
            {{ getPaymentSystemLabel(item.method) }}
          </template>

          <template #item.ref="{item}">
            <a :href="item.ref" target="_blank">
              <ca-text-truncator :max-width="18" :text="item.ref" />
            </a>
          </template>

          <template #item.file_owner_money="{item}">
            <span
              v-if="item.file_owner_money > 0"
              :class="[fileMoneyTypeColors[item.file_money_type]]"
              :title="fileMoneyTypeHint[item.file_money_type]"
            >
              {{ item.file_owner_money }}
            </span>
          </template>

          <template #item.site_owner_money="{item}">
            <span
              v-if="item.site_owner_money > 0"
              :class="[item.site_is_promo ? 'text-orange' : '']"
              :title="item.site_is_promo ? t('promo_banner_or_link') : t('site_referral')"
            >
              {{ item.site_owner_money }}
            </span>
          </template>

          <template #item.last_status="{item}">
            <div class="text-center">
              <v-chip
                small
                outlined
                :title="
                  item.last_status === 'hold'
                    ? holdReleaseDateTitle(item.hold_release.date) + ' (' + item.hold_release.diff + ')'
                    : ''
                "
                :color="
                  statusColors.hasOwnProperty(item.last_status) ? statusColors[item.last_status] : statusColors.default
                "
                >{{ item.last_status
                }}<template v-if="item.last_status === 'hold'"
                  >: {{ holdReleaseDateFormatted(item.hold_release.date) }} ({{ item.hold_release.diff }})</template
                ></v-chip
              >
            </div>
          </template>
        </v-data-table>
      </v-card>

      <div class="pt-2">
        <i18n path="pages.statistics.sales.data_table.notes.note1">
          <template #faq>
            <router-link :to="{ name: 'faq', hash: '#61' }">FAQ</router-link>
          </template>
          <template #hold>
            <b>{{ t("data_table.notes.status_hold") }}</b>
          </template>
          <template #void>
            <b>{{ t("data_table.notes.status_void") }}</b>
          </template>
          <template #refund>
            <b>{{ t("data_table.notes.status_refund") }}</b>
          </template>
        </i18n>
        <br />
        {{ t("data_table.notes.note2") }}
      </div>

      <div class="pt-1">
        <div class="v-avatar purple" style="height: 12px; min-width: 12px; width: 12px;"></div>
        {{ t("data_table.notes.type_rebill") }}
        <div class="v-avatar ml-2 grey" style="height: 12px; min-width: 12px; width: 12px;"></div>
        {{ t("data_table.notes.type_copy") }}
        <div class="v-avatar ml-2 orange" style="height: 12px; min-width: 12px; width: 12px;"></div>
        {{ t("data_table.notes.type_promo") }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import StatsPageHeader from "../StatsPageHeader";
import CaTextTruncator from "../../../components/TextTruncator";
import CaDatePicker from "../../../components/DatePicker";

export default {
  components: { CaDatePicker, CaTextTruncator, StatsPageHeader },
  metaInfo() {
    return {
      title: this.$t("titles.stats_sales")
    };
  },
  data() {
    return {
      loadingData: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      items: [],
      sort: {
        by: [],
        desc: []
      },
      filtersVisible: false,
      statuses: [],
      paymentSystems: [],
      filters: {
        status: "all",
        paymentSystem: null,
        sitesMoney: false,
        filesCopiesMoney: false,
        filesMoney: false,
        dateFrom: null,
        dateTo: null
      }
    };
  },
  computed: {
    apptype() {
      return this.$route.params.app;
    },

    dataTable() {
      return {
        headers: [
          {
            text: this.t("data_table.fields.date"),
            value: "date",
            sortable: true
          },
          {
            text: this.t("data_table.fields.email"),
            value: "email",
            sortable: false
          },
          {
            text: this.t("data_table.fields.file"),
            value: "file_name",
            sortable: true
          },
          {
            text: "",
            value: "country_code",
            sortable: true
          },
          {
            text: this.t("data_table.fields.source"),
            value: "ref",
            sortable: true
          },
          {
            text: this.t("data_table.fields.payment_system"),
            value: "method",
            sortable: true
          },
          {
            text: this.t("data_table.fields.tariff"),
            value: "tariff_title",
            sortable: true
          },
          {
            text: this.t("data_table.fields.file_sales"),
            value: "file_owner_money",
            sortable: true
          },
          {
            text: this.t("data_table.fields.site_sales"),
            value: "site_owner_money",
            sortable: true
          },
          {
            text: this.t("data_table.fields.status"),
            value: "last_status",
            sortable: true
          }
        ],
        footerProps: {
          itemsPerPageOptions: [5, 10, 25, 100]
        }
      };
    },

    fileMoneyTypeColors() {
      return {
        direct: "",
        rebill: "text-purple",
        copy: "text-gray-500"
      };
    },

    fileMoneyTypeHint() {
      return {
        direct: this.t("file_money_type.direct"),
        rebill: this.t("file_money_type.rebill"),
        copy: this.t("file_money_type.copy")
      };
    },

    statusColors() {
      return {
        hold: "primary",
        active: "success",
        refund: "warning",
        default: "info"
      };
    },

    datePickerMaxDate() {
      const now = new Date();

      return now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0") + "-" + now.getDate();
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  },
  mounted() {
    const type = this.$route.query.type;

    if (type === "files") {
      this.filtersVisible = true;
      this.filters.filesMoney = true;
      this.filters.status = "hold";
    } else if (type === "sites") {
      this.filtersVisible = true;
      this.filters.sitesMoney = true;
      this.filters.status = "hold";
    }

    this.loadData();
  },
  methods: {
    loadData() {
      this.loadingData = true;

      this.$store
        .dispatch("statistics/loadSalesData", {
          app: this.apptype,
          page: this.currentPage,
          per_page: this.itemsPerPage,
          sort_key: this.sort.by.length > 0 ? this.sort.by[0] : null,
          sort_dir: this.sort.desc.length > 0 ? (this.sort.desc[0] ? "desc" : "asc") : null,
          status: this.filters.status.value,
          payment_system: this.filters.paymentSystem,
          sites_money: this.filters.sitesMoney ? 1 : 0,
          files_money: this.filters.filesMoney ? 1 : 0,
          files_copies_money: this.filters.filesCopiesMoney ? 1 : 0,
          date_from: this.filters.dateFrom,
          date_to: this.filters.dateTo
        })
        .then(response => {
          this.items = response.data.data;
          this.loadingData = false;
          this.itemsPerPage = response.data.meta.per_page;
          this.totalItems = response.data.meta.total;
          this.statuses = [{ value: "all", text: this.$t("common.all") }].concat(
            Object.entries(response.data.statuses).map(item => {
              return {
                text: item[1],
                value: item[0]
              };
            })
          );

          this.paymentSystems = this.mapPaymentsSystemsToOptions(response.data.payment_systems);
        })
        .catch(() => {
          this.loadingData = false;

          this.$store.dispatch("app/showDialog", {
            type: "error",
            title: "Error",
            message: "Failed gettings data"
          });
        });
    },
    refreshData() {
      if (this.loadingData) {
        return;
      }

      this.loadData();
    },
    onUpdateSortDesc() {
      if (this.loadingData) {
        return;
      }

      this.loadData();
    },
    onUpdateItemsPerPage(itemsPerPage) {
      if (this.loadingData) {
        return;
      }

      this.itemsPerPage = itemsPerPage;
      this.loadData();
    },
    onPageUpdate(page) {
      if (this.loadingData) {
        return;
      }

      this.currentPage = page;
      this.loadData();
    },
    holdReleaseDateFormatted(dateString) {
      const date = new Date(dateString);

      return date.getDate() + " " + this.$t("app.months.short." + (date.getMonth() + 1));
    },
    holdReleaseDateTitle(dateString) {
      const date = new Date(dateString);

      return (
        date.getDate() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    mapPaymentsSystemsToOptions(paymentSystems) {
      let options = [{ text: this.t("all"), value: null }];

      for (let key in paymentSystems) {
        options.push({
          text: paymentSystems[key],
          value: key
        });
      }

      return options;
    },
    getPaymentSystemLabel(paymentSystemAlias) {
      const result = this.paymentSystems.filter(s => s.value === paymentSystemAlias);

      if (result.length === 1) {
        return result[0].text;
      } else {
        return this.t("unknown");
      }
    },
    t(key, params) {
      return this.$t("pages.statistics.sales." + key, params);
    }
  }
};
</script>
