<template>
  <base-card>
    <v-card-text class="v-card__text--dense">
      <div class="d-flex justify-space-between align-center">
        <div class="card-title ma-0 text-capitalize ">{{ $t(`app.sections.${currentRouteName}`) }}</div>
        <v-breadcrumbs :items="breadcrumbs">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </v-card-text>
  </base-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("app", ["appDomain", "appTitle"]),
    breadcrumbs() {
      return [
        {
          text: this.$t("app.sections.dashboard"),
          disabled: false,
          href: "/"
        },
        {
          text: this.appTitle(this.appType),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("app.sections.statistics"),
          disabled: true,
          href: "/"
        },
        {
          text: this.$t("app.sections." + this.$route.name),
          disabled: true,
          href: "/"
        }
      ];
    },
    appType() {
      return this.$route.params.app;
    },
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>
