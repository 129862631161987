<template>
  <v-menu
    :ref="refName"
    v-model="showDatePicker"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    class="ml-5"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="dateText"
        :label="placeholder"
        prepend-icon="mdi-calendar"
        readonly
        single-line
        hide-details
        class="me-3"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker v-model="date" no-title scrollable :min="minDate" :max="maxDate">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="reset">
        {{ $t("common.reset") }}
      </v-btn>
      <v-btn text color="primary" @click="cancel">
        {{ $t("common.cancel") }}
      </v-btn>
      <v-btn text color="primary" @click="onOK">
        {{ $t("common.ok") }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "CaDatePicker",
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "Date"
    },
    minDate: String,
    maxDate: String
  },
  data() {
    return {
      date: this.value,
      showDatePicker: false
    };
  },
  computed: {
    dateText() {
      return this.date;
    },
    refName() {
      return "date-picker-" + uuidv4();
    }
  },
  watch: {
    value(val) {
      this.date = val;
    }
  },
  methods: {
    onOK() {
      this.$refs[this.refName].save(this.date);

      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
    reset() {
      this.date = null;
    },
    cancel() {
      this.showDatePicker = false;
    }
  }
};
</script>
