<template>
  <span :title="title" v-html="truncatedText"></span>
</template>

<script>
export default {
  name: "CaTextTruncator",
  props: {
    text: {
      type: String,
      default: ""
    },
    maxWidth: {
      type: Number,
      default: 10
    },
    overflowSign: {
      type: String,
      default: "..."
    }
  },
  computed: {
    truncatedText() {
      if (!this.text) {
        return;
      }

      return this.text.length > this.maxWidth ? this.text.substr(0, this.maxWidth) + this.overflowSign : this.text;
    },
    title() {
      if (!this.text) {
        return;
      }

      return this.text.length > this.maxWidth ? this.text : "";
    }
  }
};
</script>
